@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  background-color: white;
  user-select: none; /* supported by Chrome and Opera */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
}

svg {
  position: relative;
}

.region {
  stroke: white;
  stroke-width: 0.05;
  stroke-miterlimit: 4;
}

.individual {
  stroke: black;
}

.standard {
  fill: #1A98B4;
}

.custom  {
  stroke: none;
}

.region:hover {
  opacity: 0.5;
}
